export enum TransactionTypeEnum {
  All = 'ALL',
  Fee = 'FEE',
  Payment = 'PAYMENT',
  Refund = 'REFUND',
  Payout = 'PAYOUT',
  Payin = 'PAYIN',
}

export enum TransactionFlowEnum {
  Incoming = 'INCOMING',
  Outgoing = 'OUTGOING',
}

export enum TimePeriodOptionEnum {
  Today = 'TODAY',
  Yesterday = 'YESTERDAY',
  Last7Days = 'LAST_7_DAYS',
  Last30Days = 'LAST_30_DAYS',
  ThisMonth = 'THIS_MONTH',
  LastMonth = 'LAST_MONTH',
  ThisYear = 'THIS_YEAR',
  LastYear = 'LAST_YEAR',
  Custom = 'CUSTOM',
}

export type MoneyAmount = {
  value: number,
  currency: string
}

export type WalletBalanceType = {
  id: string,
  providerId: string,
  totalBalance: MoneyAmount,
  availableToPayout?: MoneyAmount,
  toBeCredited?: MoneyAmount,
  securityDeposit: MoneyAmount,
  availableCapabilities: string[]
}

export type FilterEvent = {
  transactionType: TransactionTypeEnum,
  dateOption: TimePeriodOptionEnum,
}

export type TransactionOrder = {
  id?: string,
  code?: string,
  uri?: string
}

export type WalletTransaction = {
  amount: MoneyAmount,
  type: TransactionTypeEnum,
  date: string,
  flow: TransactionFlowEnum,
  order?: TransactionOrder,
};

export type TimePeriod = {
  from: string,
  until?: string,
}

export type TransactionFilter = {
  timePeriod: TimePeriod,
  type: TransactionTypeEnum,
};

export enum KYCStatusEnum {
    VERIFIED = 'VERIFIED',
    UNVERIFIED = 'UNVERIFIED',
    PENDING = 'PENDING',
    ACTION_REQUIRED = 'ACTION_REQUIRED',
    REJECTED = 'REJECTED'
}

export enum PaymentProviderStatusEnum {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    SCHEDULED = 'SCHEDULED',
}

export enum ActivationIntentTypeEnum {
    ASAP = 'ASAP',
    DATE_BASED = 'DATE_BASED',
}

export type PaymentProviderActivationIntent = {
    type: ActivationIntentTypeEnum,
    date?: string,
}

export type PaymentProvider = {
    id: 'STRIPE' | 'MANGOPAY',
    kycStatus: KYCStatusEnum,
    status: PaymentProviderStatusEnum,
    activationIntent?: PaymentProviderActivationIntent,
}

export type PaymentProviderSwitchRequest = {
    providerId: string,
    type: ActivationIntentTypeEnum,
    date?: string,
}

export type Balance = {

}
