import { WidgetResponse } from '@bookingkit-private/api-v4';
import { WidgetType } from '@/services/WidgetService/types';

export const fromApiWidgetToWidgetType = (widget: WidgetResponse): WidgetType => {
    if (!widget.widget_id) {
        throw new Error('Widget ID is missing');
    }
    if (!widget.title) {
        throw new Error('Widget title is missing');
    }
    return {
        id: widget.widget_id,
        title: widget.title,
        description: widget.description,
        configuration: widget.config,
    };
};

export default {
    fromApiWidgetToWidgetType,
};
