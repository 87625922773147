import {
    WalletV2,
    WalletBalanceV2,
    Balance,
} from '@bookingkit-private/api-v4';
import { MoneyAmount, WalletBalanceType } from '@/services/AccountingService/types';

interface BalanceWithSecurityDeposit extends WalletBalanceV2{
    security_deposit?:Balance,
}

const moneyInDecimalsFromBalance = (balance: Balance): MoneyAmount => {
    let value = balance.money.value / 100;
    if (balance.is_positive === false) {
        value = -value;
    }
    return {
        value,
        currency: balance.money.currency,
    } as MoneyAmount;
};

const getSecurityDeposit = (balance:BalanceWithSecurityDeposit | undefined) => (balance?.security_deposit ? moneyInDecimalsFromBalance(balance?.security_deposit) : undefined);

export const fromApiWalletToApplicationWallet = (wallet: WalletV2): WalletBalanceType => <WalletBalanceType>({
    id: wallet.id,
    providerId: wallet.payment_provider_id,
    totalBalance: wallet.balance?.in_wallet ? moneyInDecimalsFromBalance(wallet.balance.in_wallet) : undefined,
    securityDeposit: getSecurityDeposit(wallet.balance),
    availableToPayout: wallet.balance?.available_payout ? moneyInDecimalsFromBalance(wallet.balance.available_payout) : undefined,
    toBeCredited: wallet.balance?.incoming ? moneyInDecimalsFromBalance(wallet.balance.incoming) : undefined,
    availableCapabilities: wallet.available_capabilities ? wallet.available_capabilities : [],
});

export default {
    fromApiWalletToApplicationWallet,
};
