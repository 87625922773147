<template>
      <teleport to="#main-view"  v-if="modalView">
        <transition name="accordion" class="accordion">

            <div class="modal-container c-layer-flat"
                 :class="layoutClass"
                 v-if="showNow"
                 v-identifier="identify(modalView)">

              <div
                class="layer-overlay modal-layout"
                :class="anchorPoint"
                v-click-outside="closeModalIfAllowed">

                <div class="modal-properties">
                  <h4 class="modal-title">{{title}}</h4>
                  <close-button class="close-modal" v-if="!disableClosing" @click="closeModalIfAllowed" />
                </div>
                <div class="modal-content">
                  <component :is="modalView"
                             @should-unmount="closeModalIfAllowed"
                             :modalProperties="{...getModalProperties()}"/>
                </div>
              </div>
            </div>
        </transition>

      </teleport>

</template>

<script >
import { X as CloseButton } from 'lucide-vue-next';
import { defineAsyncComponent } from 'vue';
import { clickOutside } from '@/directives/ClickOutside';
import useApplicationState, { ModalAnchorPoints } from '@/state/application';
import SupplierSwitchLoading from '@/components/SupplierSwitchModal/SupplierSwitchLoading.vue';
import SearchLoading from '@/components/SearchModal/SearchLoading.vue';
import ShortcutLoading from '@/components/PowerShortcuts/ShortcutLoading.vue';
import WidgetCreationLoading from '@/modules/sell/pages/NewWidgets/components/WidgetCreationLaoding.vue';
import useTranslationService from '@/composables/useTranslationService';
import useIdentifyService from '@/composables/useElementIdentifierService';

export default {
    name: 'Modal',
    components: {
        CloseButton,
        SupplierSwitchModalView: defineAsyncComponent({
            loader: () => import('@/components/SupplierSwitchModal/index.vue'),
            loadingComponent: SupplierSwitchLoading,
        }),
        SupplierOnBoardingModalView: defineAsyncComponent({
            loader: () => import('@/components/OnBoardingModal/index.vue'),
        }),
        ExperienceListModalView: defineAsyncComponent({
            loader: () => import('@/components/Modals/SelectExperienceModal/index.vue'),
        }),
        PayoutModalView: defineAsyncComponent({
            loader: () => import('@/modules/wallet/WalletManagement/components/PayoutModal/index.vue'),
        }),
        NewBankAccountModalView: defineAsyncComponent({
            loader: () => import('@/modules/wallet/WalletManagement/components/PayoutModal/NewBankAccountModal/index.vue'),
        }),
        SearchModalView: defineAsyncComponent({
            loader: () => import('@/components/SearchModal/index.vue'),
            loadingComponent: SearchLoading,
        }),
        PowerShortcutsModalView: defineAsyncComponent({
            loader: () => import('@/components/PowerShortcuts/index.vue'),
            loadingComponent: ShortcutLoading,
        }),
        CreateNewWidgetModalView: defineAsyncComponent({
            loader: () => import('@/modules/sell/pages/NewWidgets/components/NewWidgetCreationModal.vue'),
            loadingComponent: WidgetCreationLoading,
        }),
        WidgetCopyCodeModalView: defineAsyncComponent({
            loader: () => import('@/modules/sell/pages/WidgetSettings/Components/WidgetCode.vue'),
            loadingComponent: WidgetCreationLoading,
        }),
        SupplierSwitchLoading,
        SearchLoading,
        ShortcutLoading,
    },
    directives: {
        clickOutside,
    },
    props: {
        disableClosing: {
            required: false,
            type: Boolean,
            default: false,
        },
        view: {
            required: false,
            type: String,
        },
        getModalProperties: {
            required: false,
            type: Function,
        },
        anchorPoint: {
            required: false,
            type: String,
            default: ModalAnchorPoints.Top,
        },
        isMicroFrontend: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        // eslint-disable-next-line no-return-assign
        this.$nextTick(() => this.showNow = true);
        window.addEventListener('keydown', this.escapeListener);
    },
    beforeUnmount() {
        window.removeEventListener('keydown', this.escapeListener);
    },
    setup() {
        const { closeModal } = useApplicationState();
        const { t } = useTranslationService();
        const { identify } = useIdentifyService({ prefix: 'modal.' });
        return { closeModal, t, identify };
    },
    data() {
        return {
            showNow: false,
        };
    },
    computed: {
        layoutClass() {
            return this.isMicroFrontend ? 'microfrontend-modal' : '';
        },
        modalView() {
            if (!this.view) { return false; }

            return this.view;
        },
        title() {
            switch (this.modalView) {
            case 'SupplierSwitchModalView':
                return this.t('supplierSearch.switchVendor');
            case 'SupplierOnBoardingModalView':
                return '';
            case 'PayoutModalView':
                return this.t('wallet.walletManagement.payoutModal.title');
            case 'SearchModalView':
                return this.t('search.search');
            case 'PowerShortcutsModalView':
                return this.t('powerShortcuts.keyboardShortcuts');
            case 'ExperienceListModalView':
                return this.t('modals.selectExperienceToAddDate.title');
            case 'CreateNewWidgetModalView':
                return this.t('sell.newWidgets.title');
            case 'WidgetCopyCodeModalView':
                return this.t('sell.newWidgets.integrateWidget');
            default:
                return false;
            }
        },
    },
    methods: {
        escapeListener(e) {
            if (e.code === 'Escape') {
                this.closeModalIfAllowed();
            }
        },
        closeModalIfAllowed() {
            if (!this.disableClosing) {
                this.showNow = false;
                this.$nextTick(() => {
                    this.closeModal();
                });
            }
        },
    },
};
</script>

<style scoped>
.modal-container {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--color-neutral-10-transparency-50);
  display: flex;
  z-index: 300;
}

@media screen and (min-width: 769px) {
  .modal-container {
    width: 100%;
  }

  .modal-container.microfrontend-modal {
    width: 100%;
  }
}

.modal-layout {
  margin: 0 auto auto;
  background-color: var(--color-neutral-1);
  min-height: 300px;
  width: inherit;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modal-layout.center {
  margin: auto;
}

@media screen and (min-width: 600px) {
  .modal-layout {
    max-height: calc(92% - 69px);
    border-radius: 0 0 var(--s6) var(--s6);
  }

  .modal-layout.center {
    border-radius: var(--s6);
    max-height: none;
  }
}

@media screen and (min-width: 640px) {
  .modal-layout {
    width: 622px;
    height: auto;
  }
}

@media screen and (min-width: 769px) {
  .modal-layout {
    max-height: 90%;
    min-width: 680px;
    width: auto;
  }
}

.modal-properties {
  display: flex;
  justify-content: space-between;
  margin: var(--s6) var(--s6) var(--s4) var(--s6);
}

.modal-title {
  margin: 0 0 0 var(--s1);
}

.close-modal {
  color: var(--color-neutral-7);
  cursor: pointer;
}

.accordion {
  transition: opacity var(--speed-fast) var(--timing-ease-iq);
}

.modal-content {
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 0 0 var(--s4) 0;
}

.accordion-enter-active {
  opacity: 0;
}

.accordion-enter-to {
  opacity: 1;
}

.accordion-leave-active {
  opacity: 1;
}

.accordion-leave-to {
  opacity: 0;
}
</style>
