export const SCOPE_ACCOUNTING = 'accounting';
export const SCOPE_ACCOUNTING_WRITE = 'accounting_write';
export const SCOPE_BOOKING = 'booking';
export const SCOPE_BOOKING_WRITE = 'booking_write';
export const SCOPE_CUSTOMIZATION = 'customization';
export const SCOPE_CUSTOMIZATION_WRITE = 'customization_write';
export const SCOPE_EVENT = 'event';
export const SCOPE_EVENT_WRITE = 'event_write';
export const SCOPE_MARKETING = 'marketing';
export const SCOPE_MARKETING_WRITE = 'marketing_write';
export const SCOPE_ORDER_CREATE_AND_EDIT = 'order_create_and_edit';
export const SCOPE_ORDER_EDIT_AFTER_SERVICE = 'order_edit_after_service';
export const SCOPE_ORDER_EDIT_REFUND_AMOUNT = 'order_edit_refund_amount';
export const SCOPE_REACH = 'reach';
export const SCOPE_REACH_WRITE = 'reach_write';
export const SCOPE_SELL_CHECKOUT_CONFIGURATION = 'sell_checkout_configuration';
export const SCOPE_SELL_CHECKOUT_CONFIGURATION_WRITE = 'sell_checkout_configuration_write';
export const SCOPE_SELL_MARKETING = 'sell_marketing';
export const SCOPE_SELL_MARKETING_WRITE = 'sell_marketing_write';
export const SCOPE_SETTING = 'setting';
export const SCOPE_SETTING_WRITE = 'setting_write';
export const SCOPE_STATISTIC = 'statistic';
export const SCOPE_STATISTICS_EXPORT = 'statistics_export';
export const SCOPE_STATISTICS_EXPORT_WRITE = 'statistics_export_write';
export const SCOPE_STATISTIC_WRITE = 'statistic_write';
export const SCOPE_USERS = 'users';
export const SCOPE_USERS_WRITE = 'users_write';

export type UserType = {
    id: string,
    name: string,
    email: string,
    locale: ApplicationLocale,
    canSwitchSupplier?: boolean
}

export const SupplierScopes = {
    [SCOPE_ACCOUNTING]: SCOPE_ACCOUNTING,
    [SCOPE_ACCOUNTING_WRITE]: SCOPE_ACCOUNTING_WRITE,
    [SCOPE_BOOKING]: SCOPE_BOOKING,
    [SCOPE_BOOKING_WRITE]: SCOPE_BOOKING_WRITE,
    [SCOPE_CUSTOMIZATION]: SCOPE_CUSTOMIZATION,
    [SCOPE_CUSTOMIZATION_WRITE]: SCOPE_CUSTOMIZATION_WRITE,
    [SCOPE_EVENT]: SCOPE_EVENT,
    [SCOPE_EVENT_WRITE]: SCOPE_EVENT_WRITE,
    [SCOPE_MARKETING]: SCOPE_MARKETING,
    [SCOPE_MARKETING_WRITE]: SCOPE_MARKETING_WRITE,
    [SCOPE_ORDER_CREATE_AND_EDIT]: SCOPE_ORDER_CREATE_AND_EDIT,
    [SCOPE_ORDER_EDIT_AFTER_SERVICE]: SCOPE_ORDER_EDIT_AFTER_SERVICE,
    [SCOPE_ORDER_EDIT_REFUND_AMOUNT]: SCOPE_ORDER_EDIT_REFUND_AMOUNT,
    [SCOPE_REACH]: SCOPE_REACH,
    [SCOPE_REACH_WRITE]: SCOPE_REACH_WRITE,
    [SCOPE_SELL_CHECKOUT_CONFIGURATION]: SCOPE_SELL_CHECKOUT_CONFIGURATION,
    [SCOPE_SELL_CHECKOUT_CONFIGURATION_WRITE]: SCOPE_SELL_CHECKOUT_CONFIGURATION_WRITE,
    [SCOPE_SELL_MARKETING]: SCOPE_SELL_MARKETING,
    [SCOPE_SELL_MARKETING_WRITE]: SCOPE_SELL_MARKETING_WRITE,
    [SCOPE_SETTING]: SCOPE_SETTING,
    [SCOPE_SETTING_WRITE]: SCOPE_SETTING_WRITE,
    [SCOPE_STATISTIC]: SCOPE_STATISTIC,
    [SCOPE_STATISTICS_EXPORT]: SCOPE_STATISTICS_EXPORT,
    [SCOPE_STATISTICS_EXPORT_WRITE]: SCOPE_STATISTICS_EXPORT_WRITE,
    [SCOPE_STATISTIC_WRITE]: SCOPE_STATISTIC_WRITE,
    [SCOPE_USERS]: SCOPE_USERS,
    [SCOPE_USERS_WRITE]: SCOPE_USERS_WRITE,
};
export type SupplierScopeType = keyof typeof SupplierScopes;

export const AccountStatus = {
    ACTIVE: 'ACTIVE',
    INCOMPLETE: 'INCOMPLETE',
    RESTRICTED: 'RESTRICTED',
    CANCELED: 'CANCELED',
};

export type AccountStatusType = keyof typeof AccountStatus;

export type SupplierType = {
    id: string,
    name: string,
    scopes: SupplierScopeType[],
    logo?: string,
    accountNumber?: string,
    accountStatus?: AccountStatusType,
}
